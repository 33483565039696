import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import GalleryStyle from '../styles/gallery.module.css';
import EmptyState from '../components/empty-state.jsx';

export const query = graphql`
	query {
		cms {
			allGalleries {
				media {
					originalUrl
					type
					... on CMS_OEmbedRich {
						html
					}
					... on CMS_OEmbedVideo {
						html
					}
				}
				_label_
				id
			}
		}
	}
`;

const GalleryPageContent = ({ data }) => {
	return (
		<div>
			<Layout>
				<div>
					<h1>Gallery</h1>
					{data.cms.allGalleries.length === 0 ? (
						<EmptyState />
					) : (
						<div className={GalleryStyle.gallery}>
							{data.cms.allGalleries.map(gallery => {
								return (
									<div className={GalleryStyle.item} key={gallery.id}>
										<div
											dangerouslySetInnerHTML={{
												__html: gallery.media.html.replace(
													/data-width="\d+"/,
													' data-width="200" '
												)
											}}
										></div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</Layout>
		</div>
	);
};

export default GalleryPageContent;
