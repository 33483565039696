import React from 'react';
import SVG from '../images/empty1.svg';
import styles from '../styles/emptyState.module.css';

function EmptyState() {
	return (
		<div className={styles.emptyStateContainer}>
			<img src={SVG} alt='' />
			<div className={styles.emptyStateText}>
				<h1>There is currently nothing here :(</h1>
				<p>Visit us again soon to see the latest updates.</p>
			</div>
		</div>
	);
}

export default EmptyState;
